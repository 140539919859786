import React, { FunctionComponent } from 'react';
import Heading from "../components/Heading";
import Section from "../components/Section";
import EnvelopeIcon from "../assets/icons/ico_envelope.svg";
import ContactForm from "../forms/ContactForm";

const Contact: FunctionComponent = () => {

    return (
        <Section className={'py-20'}>
            <div className={'grid grid-cols-5 gap-16'}>
                <div className={'col-span-2'}>
                    <Heading type='h1'>Contact us</Heading>
                    <Heading type='h4' className={'mb-8'}>Our team is happy to answer your questions. Fill out the form and we’ll be in touch as soon as possible.</Heading>
                    <div  className={'text-ink-60 whitespace-pre-wrap mb-6'}>
                        Legalia, s.r.o.<br/>
                        Miletičova 1<br/>
                        821 08 Bratislava<br/>
                        IČO: 46 488 995<br/>
                        DIČ: 2820015759<br/>
                        IČ DPH: SK 2820015759<br/>
                    </div>
                    <div className={'space-y-4 mb-8'}>
                        <p className={'flex text-blue-100 space-x-3'}>
                            <EnvelopeIcon className={'w-6 h-6 text-blue-100'}/>
                            <a href="mailto:info@legalia.com" className={'underline'}>Show on the map</a>
                        </p>
                        <p className={'flex text-blue-100 space-x-3'}>
                            <EnvelopeIcon className={'w-6 h-6 text-blue-100'}/>
                            <a href="mailto:info@legalia.com" className={'underline'}>info@legalia.com</a>
                        </p>
                    </div>
                    <div>
                        <iframe
                            width="100%"
                            height="350"
                            frameBorder="0"
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAyPlsPv5GVRESHBZCu2bUvsbu-xg2kv1M&q=Miletičova+1,+821+08+Bratislava"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <div className={'col-span-3'}>
                    <div className="bg-ink-5 p-12 rounded-md">
                        <Heading type='h4' className={'mb-8 font-medium'}>Máte nejaké otázky? Napíšte nám.</Heading>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Contact;
