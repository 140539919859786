import React, { FunctionComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormField from "../components/FormField";
import Button from "../components/Button/Button";

type ContactFormProps = {
};

type ContactFormValues = {
    email: string,
    fullName: string,
    phone: string,
    message: string,
};

const ContactFormValidation = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    fullName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    phone: Yup.string().matches(/^(\+[1234]{3})([\d]{9})$/).required('Required'),
    message: Yup.string().required('Required')
});

const ContactForm: FunctionComponent<ContactFormProps> = (props) => {
    const initialValues: ContactFormValues = {
        email: '',
        fullName: '',
        phone: '',
        message: '',
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={ContactFormValidation}
                onSubmit={(values: ContactFormValues) => {
                    // same shape as initial values
                    console.log(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className={'space-y-6 mb-6'}>
                            <FormField name="email" type="text" label="Email address" markRequired placeholder={"name@address.com"}/>
                            <FormField name="fullName" type="text" label="Full name" markRequired placeholder={"David Johanson"}/>
                            <FormField
                                name="phone"
                                type="text"
                                label="Phone number"
                                placeholder={"+421 000 000 000"}
                                hint={'Please use your country prefix.'}
                                markRequired
                            />
                            <FormField name="message" type="textarea" label="Message" markRequired placeholder={"Insert your message here..."}/>
                        </div>
                        <Button type="submit" variant={'secondary'}>Send</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactForm;
